<template>
   <div class="cont">
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>运营</el-breadcrumb-item>
            <el-breadcrumb-item>全员营销</el-breadcrumb-item>
            <el-breadcrumb-item>营销分组</el-breadcrumb-item>
         </el-breadcrumb>
      </el-row>
      <!-- 查询框 -->
      <el-row class="content-box">
         <!-- 表格 -->
         <el-row class="table-box">
            <p class="tips" >奖励优先级说明：如分销人员处于多种等级规则中，则当前人员按奖励优先级高的第一种奖励政策进行奖励，优先级数字越小，优先级越高！</p>
            <el-button class="add-btn bg-gradient"  type="primary" @click="addSeckillProduct('','添加')">添加营销分组</el-button>
            <el-table
                  ref="mainTable"
                  border
                  v-loading="loading"
                  :data="grouplist"
                  :stripe="true"
            >
               <el-table-column label="序号" width="60">
                  <template slot-scope="scope">
                     {{scope.$index + 1}}
                  </template>
               </el-table-column>
               <el-table-column prop="groupName" label="分组名称" ></el-table-column>
               <el-table-column prop="groupType" label="组成类型">
                  <template slot-scope="scope">

                     <span>{{ scope.row.groupType | filterStatus }}</span>
                  </template>
               </el-table-column>
<!--               <el-table-column prop="createOperator" label="当前人数">-->
<!--                  <template slot-scope="scope">-->

<!--                     <span>{{ scope.row.marketingGroupUsers.length}}</span>-->
<!--                  </template>-->
<!--               </el-table-column>-->
               <el-table-column prop="sortNo" label="奖励优先级"></el-table-column>
               <el-table-column prop="seckillPrice" label="奖励优先级调整">
                  <template slot-scope="scope">
                     <el-button type="text" @click="moveMarketingGroup(scope.row.id,-1)">上调</el-button>
                     <el-button type="text" @click="moveMarketingGroup(scope.row.id,1)">下降</el-button>
                  </template>
               </el-table-column>

               <el-table-column prop="status" label="操作" >
                  <template slot-scope="scope">
                        <el-button type="text" @click="addSeckillProduct(scope.row,'编辑')">编辑</el-button>
                        <el-button type="text" style="color: red" @click="deleteroom(scope.row.id)">删除</el-button>
                  </template>
               </el-table-column>

            </el-table>
            <!-- 分页 -->
         </el-row>
      </el-row>

      <el-dialog :visible.sync="dialogVisible" width="50%" @closed="closed">
         <div slot="title" class="header-title">
            <span class="title-text">{{btntype}}分组</span>
         </div>
         <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm" >
            <el-form-item label="分组名称" prop="groupName" style="width: 50%">
               <el-input v-model="ruleForm.groupName"></el-input>
            </el-form-item>
<!--            <el-form-item label="奖励优先级" prop="groupName" style="width: 50%">-->
<!--               <el-input v-model="ruleForm.groupName"></el-input>-->
<!--            </el-form-item>-->
            <el-form-item label="组成类型" prop="groupType">
               <el-radio-group v-model="ruleForm.groupType">
                  <el-radio label="MEMBER_LEVEL">会员等级</el-radio>
                  <el-radio label="DESIGNATED_MEMBERS">指定会员</el-radio>
                  <el-radio label="ALL">全部会员</el-radio>
               </el-radio-group>
            </el-form-item>
            <el-form-item label="" prop="region" v-if="ruleForm.groupType == 'MEMBER_LEVEL' ">
               <el-checkbox-group v-model="ruleForm.userIds" @change="handleCheckedCitiesChange" style="height: 250px;overflow-y: scroll;">
                  <el-checkbox v-for="(item,index) in medalList" :label="item.id" :key="index" style="width: 50%;">
                     {{item.medalName}}的用户
                  </el-checkbox>
               </el-checkbox-group>

            </el-form-item>

            <el-form-item label="" prop="region" v-if="ruleForm.groupType == 'DESIGNATED_MEMBERS' ">
               <el-button class="add-btn bg-gradient" style="margin: 10px 0" type="primary" @click="addmeberlist">添加人员</el-button>
               <el-table :data="meberlist"  height="280">
                  <el-table-column property="roomNo" label="序号" width="60">
                     <template slot-scope="scope">
                        {{scope.$index + 1}}
                     </template>
                  </el-table-column>
                  <el-table-column property="id" label="会员编号" width="200"></el-table-column>
                  <el-table-column property="userName" label="会员姓名"></el-table-column>
                  <el-table-column property="nickName" label="会员昵称"></el-table-column>
                  <el-table-column property="invitationCode" label="邀请码"></el-table-column>
                  <el-table-column property="mobile" label="手机号码"></el-table-column>
                  <el-table-column property="medalName" label="会员等级"></el-table-column>
                  <el-table-column property="address" label="操作">
                     <template slot-scope="scope">
                        <el-button type="text" @click="deleterow(scope.row,scope.$index)">删除</el-button>
                     </template>
                  </el-table-column>
               </el-table>
            </el-form-item>
         </el-form>
         <el-dialog
               width="50%"
               title="添加人员"
               min-width="300px"
               close="closed"
               :visible.sync="memberVisible"
               append-to-body>

            <el-row class="search-row">
               <el-row class="search-item">

                  <span>姓名查询：</span><el-input class="width-150 m-left-5" placeholder="输入会员姓名查询"  v-model="nickNameQ"></el-input>
                  <span style="margin-left: 30px">电话号码查询：</span> <el-input class="width-150 m-left-5" placeholder="输入电话号码查询"  v-model="mobileQ"></el-input>
                  <el-button   type="primary" class="add-btn bg-gradient mg15" @click="getMember">查询</el-button>
                  <el-button   type="primary" class="add-btn bg-gradient mg15" @click="restgetMember">重置</el-button>
               </el-row>


            </el-row>
            <el-table :data="meberslist" height="280" highlight-current-rowref="orderTable"  ref="dataTable" tooltip-effect="light" @selection-change="getCurrentRow"
            >
               <!--会员列表-->
               <el-table-column key="1" label="选择" type="selection" prop="editshow" width="60" />
               <el-table-column key="2" center prop="userName" label="会员姓名" >

               </el-table-column>

               <el-table-column key="3" prop="nickName" label="会员昵称" />

               <el-table-column key="4" prop="mobile" label="手机号码" >

               </el-table-column>
            </el-table>
            <div class="dialog-bottombox" style="text-align: center;margin-top: 20px">
               <el-button type="primary" @click="identifymember()">确 定</el-button>

               <el-button @click="memberVisible = false">取 消</el-button>
            </div>
         </el-dialog>
         <div class="dialog-bottombox" style="text-align: center;">
            <el-button type="primary" class="bg-gradient" @click="saveMarketingGroup">确 定</el-button>
            <el-button @click="dialogVisible = false">取 消</el-button>
         </div>
      </el-dialog>
   </div>
</template>

<script>
import {urlObj , smtech} from '@/api/interface'
import { marketing } from '@/api/interface/smtech'
import { CustomArray } from '@/common/js/common'
import { mapState } from "vuex"; // 公共js
export default {
   watch: {
      hotelInfo: {
         handler(newVal, oldVal) {
            if (newVal.id !== oldVal.id && oldVal) {
               this.ruleForm.hotelId = this.hotelInfo.id
               this.hotelCode = this.hotelInfo.hotelCode
                this.getMarketinggroup()
                this.getMedals()
                this.getMember()
            }
         }
      }
   },
   computed: {
      ...mapState(['hotelInfo'])
   },
   data(){
      return{
         ruleForm:{
            groupName:'',
            groupType:'MEMBER_LEVEL',
            userIds:[],
            hotelId:'',
            id:'',
         },
         total:0,
         groupName:'',
         nickNameQ:'',
         mobileQ:'',
         btntype:'',
         grouplist:[],
         loading:true,
         medalList:[],
         meberlist:[],
         checkmeberlist:[],
         reservemeberlist:[],
         meberslist:[],
         facilityIds:[],
         checkAll:[],
         cities:[],
         limit:999,
         page:1,
         memberVisible:false,
         dialogVisible:false,

         //校验
         rules: {
            groupName: [
               { required: true, message: '请输入分组名称', trigger: 'blur' },
            ],
         }
      }

   },
   mounted() {
      this.ruleForm.hotelId = this.hotelInfo.id
      this.timeLimit = this.couponLimit = sessionStorage.getItem('pageSize') * 1
      this.getMarketinggroup()
      this.getMedals()
      this.getMember()
   },
   methods: {
      // 获取会员等级
      getMedals(){
         const url = urlObj.memberLevels
         const param = { hotelId: this.hotelInfo.id, }
         if (JSON.parse(sessionStorage.getItem('hotelInfo')).accountType === 'HOTEL') {
            param.hotelId = this.hotelInfo.hotelId
         }else {
            param.companyId = this.hotelInfo.storeId
         }
         this.$axios.post(url, param).then(res => {
            if (res.success) {
               this.medalList = res.records
            }
         })
      },
      //弹窗关闭后触发事件
      closed(){
         this.ruleForm.userIds = []
         this.ruleForm.id = ''
         this.ruleForm.groupName = ''
         this.ruleForm.groupType = 'MEMBER_LEVEL'
         this.meberlist = []
         this.nickNameQ = ''
         this.mobileQ = ''
         this.$nextTick(()=>{
          this.$refs.dataTable.clearSelection();
         })

      },
      //会员列表表格显示
      addmeberlist(){
         this.memberVisible = true
         this.meberslist.forEach(item=>{
            this.meberlist.forEach(items=> {
               if (items.id == item.id){
                  this.$nextTick(()=>{
                     this.$refs.dataTable.toggleRowSelection(item,true);
                  })

               }
            })

         })

      },
      //删除营销分组
      deleteroom(id){
         this.$confirm('此操作将永久删除该营销分组, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
         }).then(() => {
            const url = marketing.deleteMarketingGroup + id
            let param={}
            this.$axios.post(url, param).then((res) => {
               if(res.success){
                  this.$message.success('删除成功')
                  this.getMarketinggroup()
               }
            })
         }).catch(() => {
            this.$message({
               type: 'info',
               message: '已取消删除'
            });
         });


      },
      //重置
      restgetMember(){
         this.nickNameQ = ''
         this.mobileQ = ''
         this.getMember()
      },
      // 获取会员列表
      getMember(){
         const param = {
            keyword: this.nickNameQ,
            mobile: this.mobileQ,
            level: this.medalCodeQ,
            userSource: this.userSourceQ,
            companyId: this.hotelInfo.storeId
         }
         const url = urlObj.members
         this.$axios.post(url, param, 'json').then((res) => {
            if(res.success){
               this.tableLoad = false
               this.total = res.total
               this.meberslist = res.records
               this.meberslist.forEach(item => {
                  if (item.hasOwnProperty('headPortrait')){
                     if (item.headPortrait.substr(0,4) !== 'http'){
                        item.headPortrait = item.headPortrait && window.getResourcesHost() + item.headPortrait
                     }
                  }
                  item.check = false
               })
               if (this.meberlist.length != 0){
                  this.meberlist.forEach(item=>{
                     this.meberslist.forEach(items=>{
                        if (item.id == items.id){
                           this.checkmeberlist = []
                           this.$nextTick(()=>{
                              this.$refs.dataTable.toggleRowSelection(items,true);
                           })
                        }
                     })
                  })
               }
            }
         })
      },
      //确定所选会员
      identifymember(){
         this.ruleForm.userIds = []
            this.checkmeberlist.forEach(item=>{
               this.meberlist.push(item)
            })
         let customArray = new CustomArray(this.meberlist)
         this.meberlist= customArray.unique()
         this.meberlist.forEach(item=>{
            this.ruleForm.userIds.push(item.id)
         })
       this.memberVisible = false
         this.restgetMember()
      },
      //会员表格单选框事件
      getCurrentRow(val) {
         this.checkmeberlist = val
      },
      //删除选中的会员
      deleterow(val,i){
         let list = []
         this.meberlist.splice(i,1)
         this.meberlist.forEach(item=>{
            list.push(item.id)
         })
         this.$nextTick(()=>{
            this.meberslist.forEach(item=>{
               if (val.id == item.id){
                  this.$refs.dataTable.toggleRowSelection(item,false);
               }
            })
         })
         this.ruleForm.userIds = list
      },
      //复选框组
      handleCheckedCitiesChange(value) {
         this.facilityIds = value
         let checkedCount = value.length;
         this.checkAll = checkedCount === this.cities.length;
         this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;
      },

      //修改分组等级
      moveMarketingGroup(id,step){
         const url = marketing.moveMarketingGroup
         const param = {
            hotelId:this.hotelInfo.id,
            step:step,
            id:id
         }
         this.$axios.post(url, param).then(res => {
            if (res.success) {
               this.$message.success('调整成功')
               this.getMarketinggroup()
            }
         })
      },

      // 获取营销分组列表
      getMarketinggroup(){
         const url = marketing.marketingGroups
         const param = {
            hotelId: this.hotelInfo.id
         }
         this.$axios.post(url, param).then(res => {
            if (res.success) {
               this.total = res.total
               this.loading = false
               this.grouplist = res.records
            }
         })
      },
      //保存分组信息
      saveMarketingGroup(){
         if (this.ruleForm.groupName == ''){
            this.$message.error('请输入营销分组名称')
            return
         }
         if (this.ruleForm.groupType == 'MEMBER_LEVE' && this.facilityIds !=''){
            this.ruleForm.userIds = this.facilityIds.join(',')
         }
         if (this.ruleForm.groupType == 'DESIGNATED_MEMBERS' && this.facilityIds !=''){
            this.ruleForm.userIds = this.ruleForm.userIds.join(',')
         }
         if (this.ruleForm.userIds.length == 0 && this.ruleForm.groupType != 'ALL'){
               this.$message.error('请选择参与分组人员')
               return
            }
         if (this.ruleForm.userIds.length != 0 && this.facilityIds ==''){
            this.ruleForm.userIds = this.ruleForm.userIds.join(',')
         }
         const url = marketing.saveMarketingGroup
         const param = this.ruleForm
         this.$axios.post(url, param).then(res => {
            if (res.success) {
               this.$message.success('保存成功')
               this.closed()
               this.getMarketinggroup()
               this.memberVisible = false
               this.dialogVisible = false
            }
         })
      },

      // 改变每页数
      couponPageNum(num){
         this.couponLimit = num
         this.getMarketinggroup()
      },
      // 改变当前页
      couponCurrPage(num){
         this.couponPage = num
         this.getMarketinggroup()
      },
      // 新增编辑营销分组
      addSeckillProduct(val,type){
         this.btntype = type
         if (val){
            this.ruleForm.userIds = []
            this.ruleForm.id = val.id
            this.ruleForm.groupName = val.groupName
            this.ruleForm.groupType = val.groupType
            val.marketingGroupUsers.forEach(item=>{
               this.ruleForm.userIds.push(item.userId)
            })
               this.meberslist.forEach(item=>{
                  this.ruleForm.userIds.forEach((items,index)=>{
                     if (item.id == items){
                        this.meberlist[index] = item
                     }
                  })
               })
                  val.marketingGroupUsers.forEach(items=>{
                     this.meberslist.forEach(item=>{
                        if (items.userId == item.id){
                           this.$nextTick(()=>{
                              this.$refs.dataTable.toggleRowSelection(item,true);
                           })
                        }
                  })
               })
         }
         this.dialogVisible = true
      },
   },
   filters: {
      filterStatus(val){
         switch (val) {
            case 'MEMBER_LEVEL':
               return val = '会员等级'
            case 'DESIGNATED_MEMBERS':
               return val = '指定会员'
            case 'ALL':
               return val = '全部会员'
         }
      }
   }
}
</script>

<style lang="scss" scoped>
.cont{
   .search-box{
      display: flex; padding: 10px 0 20px 0;
      .el-row{ margin: 0 10px 0 0 }
   }
   .table-box{
      .add-btn { margin-bottom: 10px; float: right }
      .member-info{
         display: flex;
         img { width: 60px; margin-right: 10px }
      }
      .tips{
         float: left;margin-top: 10px;opacity: 0.5
      }

   }
   .des{ color: red; margin-right: 5px }


}
.mg15{
   margin:30px 15px;
   width: 98px;
}
/deep/ .el-table th.gutter{
   /*
   * 解决element-ui 表格篡位的问题 👇
   */
   display: table-cell!important;
}
/deep/ .el-table--enable-row-transition .el-table__body td{
   border-bottom: 1px solid #EBEEF5;

}
/deep/ .el-table th.is-leaf, .el-table td{
   background:#EBEEF5 ;
}
/deep/.el-dialog__header {
   padding: 20px;
   padding-bottom: 10px;
   font-size: 18px;
   color: #fff;
   background: #2d8cf0;
   border-radius: 10px 10px 0 0;
}

/deep/.el-dialog{
   position: relative;
   margin: 0 auto 50px;
   background: #FFFFFF;
   border-radius: 10px;
   -webkit-box-sizing: border-box;
   box-sizing: border-box;
   width: 50%;
}
/deep/.el-dialog__body{
   padding: 30px 20px;
   color: #606266;
   font-size: 14px;
   word-break: break-all;
   margin: 0 30px;
}
/deep/el-table el-table--fit el-table--enable-row-hover el-table--enable-row-transition{
   border-radius: 10px;
   border: 1px solid #c7e0f5;
}

/deep/.el-table--fit{
   border-right: 0;
   border-bottom: 0;
}
/deep/ .cell label>.el-radio__label{
   display: none
}
/deep/ .el-table thead.is-group th {
}
/deep/.el-radio-button__inner{
   margin: 0 10px;
   border-radius: 4px !important;
   border: 1px solid #dedede;
}

</style>
